import React from "react";
import { ThemeProvider, createTheme, Typography, Box } from '@mui/material';

const theme = createTheme({
    typography: {
        h1: {
            fontFamily: "Helvetica",
            fontSize: "3rem"
        },
        h5: {
            fontFamily: "Helvetica",
            fontSize: "1.2rem"
        }
    }
});

export default function PrintsForSale() {
    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                bgcolor="black"
            >
                <Typography variant="h5" style={{ color: 'white' }}>
                    work in progress maybe
                </Typography>
            </Box>
        </ThemeProvider>
    );
}
