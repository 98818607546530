import * as React from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Footer from "./Footer";

export default function Photography() {
    const theme = createTheme({
        typography: {
            h1: {
                fontFamily: "Helvetica",
                fontSize: "3rem"
            },
            h5: {
                fontFamily: "Helvetica",
                fontSize: "1.2rem"
            },
        }
    });

    const themeForBreakpoints = createTheme();
    const sm = useMediaQuery(themeForBreakpoints.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="start"
                height="88vh"
                marginTop="5vh"
            >
                <Typography variant={sm ? "h5" : "h1"} align={"center"}>// photography
                    <List sx={{ marginLeft: '2vw' }}>
                        <ListItem><a target="_blank" href="https://www.leonidpesenti.com/portfolio2024.pdf" style={{ color: 'black', textDecoration: 'none' }}>// portfolio.pdf</a></ListItem>

                        <ListItem><Link to="/ph/untitledbodyproject" style={{ color: 'black', textDecoration: 'none' }}>// untitled body project</Link></ListItem>

                        {/* "manuel technique" link after "portfolio" */}
                        <ListItem><a target="_blank" href="/pesenti_leonid_manuel_technique_2024.pdf" style={{ color: 'black', textDecoration: 'none' }}>// "manuel technique"</a></ListItem>

                        <ListItem><Link to="/ph/pahf" style={{ color: 'black', textDecoration: 'none' }}>// "post-apocalyptic high fashion"</Link></ListItem>
                        <ListItem><Link to="/ph/capitals2023" style={{ color: 'black', textDecoration: 'none' }}>// capitals 2023</Link></ListItem>

                        {/* Removed "architecture" and "fashion" links */}
                        {/* <ListItem><Link to="/ph/architecture" style={{ color: 'black', textDecoration: 'none' }}>// architecture</Link></ListItem> */}
                        {/* <ListItem><Link to="/ph/fashion" style={{ color: 'black', textDecoration: 'none' }}>// fashion</Link></ListItem> */}

                        <ListItem><Link to="/ph/portraits" style={{ color: 'black', textDecoration: 'none' }}>// portraits</Link></ListItem>
                        <ListItem><Link to="/ph/ukraine2022" style={{ color: 'black', textDecoration: 'none' }}>// ukraine 2022</Link></ListItem>

                        {/* Added "prints for sale" link */}
                        <ListItem><Link to="/ph/prints" style={{ color: '#808080', textDecoration: 'none' }}>// prints for sale</Link></ListItem>
                    </List>
                </Typography>
            </Box>
            <Footer />
        </ThemeProvider>
    );
}
