import * as React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ThemeProvider, createTheme, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import pic from './Photographies/photography/tq1yA46.jpeg';
import Footer from "./Footer.jsx";

export default function About() {
    const [isRevealed, setIsRevealed] = React.useState(false);

    const theme = createTheme({
        typography: {
            h1: {
                fontFamily: "Helvetica",
                fontSize: "3rem"
            },
            h3: {
                fontFamily: "Helvetica",
                fontSize: "1rem"
            },
            buttonText: {
                fontFamily: "Helvetica",
                fontSize: "1rem",
                textTransform: "none",
                color: "#fff",
            }
        }
    });

    const themeForBreakpoints = createTheme();

    const sm = useMediaQuery(themeForBreakpoints.breakpoints.down('sm'));

    const handleReveal = () => {
        setIsRevealed(true);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="start"
                alignContent={"center"}
                height="88vh"
                marginTop="5vh"
                maxWidth="100vw"
            >
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="100vw">
                    <Grid item xs={12}>
                        <Typography variant="h1">// about</Typography>
                    </Grid>
                    <Grid item xs={12} margin="5vw" marginBottom={!sm ? '2vw' : '0'} width={'90vw'}>
                        <Typography variant="h3"><b>russian-born switzerland-based ex mathematics and psychology student, now studying in ECAL</b></Typography>

                        <Typography variant="h3"><br />
                        <br />
                        //  between Aug 20th and Sep 4th 2022 my project <Link to="/ph/pahf">"post-apocalyptic high fashion"</Link> was exhibited at <a target="_blank" href="https://www.gallerialaloggia.ch">Galleria La Loggia</a></Typography>
                        
                        <Typography variant="h3"><br />
                        <br />
                        //  between Mar 2nd and Jun 2nd 2023 the F4 manifesto <a target="_blank" href="https://www.instagram.com/p/CpkcrBcqXdx/">"mute expression"</a> was exhibited at <a target="_blank" href="https://www.instagram.com/p/CpYHKGuK-ad">SUPSI Mendrisio</a></Typography>

                        <Typography variant="h3"><br />
                        <br />
                        //  on december 2nd 2023 I published <a target="_blank" href="https://bit.ly/makingmovesonthedancefloor">Making Moves on the Dancefloor</a>, a 7-track EP, and hosted a <a target="_blank" href="https://www.instagram.com/p/C0wDwKLN7sS/">release party</a></Typography>

                        {/* Minimal Edgy Reveal Button */}
                        {!isRevealed ? (
                            <Box
                                onClick={handleReveal}
                                sx={{
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    cursor: 'pointer',
                                    width: '100%', // Matches the width of the Typography above
                                    textAlign: 'left', // Aligns text to the left
                                    padding: '10px 0', // Vertical padding
                                    marginTop: '20px', // Space from the text above
                                }}
                            >
                                <Typography variant="buttonText" sx={{ paddingLeft: '10px' }}>
                                    // tap to reveal the future
                                </Typography>
                            </Box>
                        ) : (
                            <Typography variant="h3">
                                <br />
                                <br />
                                //  on february 7th 2025 I published <a target="_blank" href="https://share.amuse.io/album/leonid-at-the-edge">AT THE EDGE</a>, an 11-track LP, and organized the <a target="_blank" href="https://linktr.ee/hennibbale/">release party</a>
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} margin="5vw" marginTop={sm ? '5vw' : '0'} width={'90vw'}>
                        <img src={pic} width={'100%'} alt="Photography" />
                    </Grid>
                </Grid>
            </Box>
            <Footer fixed></Footer>
        </ThemeProvider>
    );
}
