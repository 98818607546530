import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import pic_1 from './Photographies/photography/events/backstreetnoise240525-theneue.png';
import pic_2 from './Photographies/photography/events/cafexx240607.jpg';
import pic_3 from './Photographies/photography/events/attheedge.gif';
import pic_4 from './Photographies/photography/events/attheedge_cover.png';
import Footer from "./Footer.jsx";
import './styles.css'; // Adjust the path based on your file structure

export default function Events() {
    const theme = createTheme({
        typography: {
            h1: {
                fontFamily: "Helvetica",
                fontSize: "3rem"
            },
            h3: {
                fontFamily: "Helvetica",
                fontSize: "1rem"
            }
        }
    });

    const themeForBreakpoints = createTheme();

    const sm = useMediaQuery(themeForBreakpoints.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="start"
                alignContent={"center"}
                height="88vh"
                marginTop="5vh"
                maxWidth="100vw"
            >
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="100vw">
                    <Grid item xs={12}>
                        <Typography variant="h1">// events</Typography>
                    </Grid>

                        <Grid item xs={12} width="80vw" marginTop="2vw" marginBottom="1vw">
                            <Typography style={{ fontSize: "1rem", textAlign: "center" }}>
                             come celebrate the release of "AT THE EDGE" on a 4-lineup night in Fribourg, featuring a visual show and an art exposition in the club room. no photos or videos on the dancefloor.
                            </Typography>
                        </Grid>

                    <Grid item xs={12} width="80vw" className="image-container" style={{ marginTop:"1vw"}}>
                    <a href="https://linktr.ee/hennibbale" target="_blank" rel="noopener noreferrer">
                        <img src={pic_3} alt="Event Poster 3" />
                    </a>
                    </Grid>

                    <Grid item xs={12} width="80vw" className="image-container" style={{ marginTop:"0.5vw"}}>
                    <a href="https://share.amuse.io/album/leonid-at-the-edge" target="_blank" rel="noopener noreferrer">
                        <img src={pic_4} alt="Album Cover" />
                    </a>
                    </Grid>
                    
                    <Typography style={{fontSize:"1rem", marginTop:"5vh", padding:"1vh", marginBottom:"2vh"}}>some of my recorded DJ sets and live events</Typography>
                    <Grid item xs={12} margintop="1vw" marginBottom="1vw" width='90vw' alignContent="center">
                        <iframe
                            width="100%"
                            height="450"
                            scrolling="no"
                            frameBorder="no"
                            allow="autoplay"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1831730748&color=%230a0a0a&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                        ></iframe>
                        <div style={{
                            fontSize: '10px',
                            color: '#cccccc',
                            lineBreak: 'anywhere',
                            wordBreak: 'normal',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
                            fontWeight: 100,
                        }}>
                            <a
                                href="https://soundcloud.com/leon1d"
                                title="Leonid"
                                target="_blank"
                                style={{ color: '#cccccc', textDecoration: 'none' }}
                            >
                                Leonid
                            </a> ·
                            <a
                                href="https://soundcloud.com/leon1d/sets/mixes"
                                title="Mixes"
                                target="_blank"
                                style={{ color: '#cccccc', textDecoration: 'none' }}
                            >
                                Mixes
                            </a>
                        </div>
                    </Grid>



                    <Grid item xs={12} width="80vw" className="image-container" style={{ marginTop: sm ? '5vw' : '0' }}>
                        <img src={pic_2} alt="Event Poster 2" />
                    </Grid>

                    <Grid item xs={12} width="80vw" className="image-container" style={{ marginTop: sm ? '5vw' : '0' }}>
                        <img src={pic_1} alt="Event Poster 1" />
                    </Grid>
                </Grid>
            </Box>
            <Footer fixed></Footer>
        </ThemeProvider>
    );
}
