import * as React from "react";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, Typography, Badge } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from "./Footer";

export default function Home() {

    const themeForBreakpoints = createTheme();

    const sm = useMediaQuery(themeForBreakpoints.breakpoints.down('sm'));
    const tablet = useMediaQuery('(min-width:768px) and (max-width:1000px)');

    const theme = createTheme({
        typography: {
            h1: {
                fontFamily: "Helvetica",
                fontSize: "2rem",
            },
            h5: {
                fontFamily: "Helvetica",
                fontSize: "1.5rem",
                fontWeight: 300,
            }
        }
    });

    // Consistent spacing constant between the top and bottom of the GIF (same as the space below it)
    const textSpacing = "5vh"; // Space between "Leonid Pesenti" and the text

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems={!tablet ? "center" : "flex-start"}
                height="93vh"
                maxWidth="100vw"
                marginLeft="20vw"
                marginBottom="20vw"
            >
                <Grid container direction={!sm ? "row" : "column"} >
                    <Grid item xs={!sm ? 5 : 12} marginRight={!sm ? "0%" : "25%"} marginTop={0.3*textSpacing}> {/* Add marginTop here */}
                        <img
                            src="https://i.imgur.com/Ot3ug75.gif"
                            width={!sm ? "50%" : "100%"}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography component={'span'}>
                            <Grid container direction="column">
                                <Grid item xs={!tablet ? 6 : 12} sx={{ display: "flex", justifyContent: "flex-end" }} marginRight={sm ? "20vw" : "0"}>
                                    <Typography variant={"h1"} marginBottom={textSpacing}>
                                        Leonid Pesenti
                                    </Typography>
                                </Grid>
                                <Grid container marginTop={textSpacing} marginLeft={tablet ? "5vw" : sm ? "-20vw" : "0"} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Grid item width={!sm ? "65%" : "90%"} marginBottom="3vw" sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Link to="/about" style={{ color: 'black' }}><Typography variant="h5">about</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item width={!sm ? "65%" : "90%"} marginBottom="3vw" sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Link to="/events" style={{ color: 'black', textDecoration: 'none', position: 'relative' }}>
    <Badge
        badgeContent={1}
        sx={{
            '& .MuiBadge-badge': {
                backgroundColor: 'rgb(234, 79, 61)', // Custom bubble color
                color: 'white', // Text color inside the badge
                fontSize: '0.7rem', // Smaller font
                height: '18px', // Smaller height
                minWidth: '18px', // Smaller width
                borderRadius: '50%',
                top: 5, // Lower the bubble
                right: -10, // Bring the bubble closer to the text
            },
        }}
    >
        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
            events
        </Typography>
    </Badge>
</Link>
                                    </Grid>
                                    <Grid item width={!sm ? "65%" : "90%"} marginBottom="3vw" sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Link to="/music" style={{ color: 'black' }}><Typography variant="h5">music</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item width={!sm ? "65%" : "90%"} marginBottom="3vw" sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Link to="/ph" style={{ color: 'black' }}><Typography variant="h5">photography</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item width={!sm ? "65%" : "90%"} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Link to="/contact" style={{ color: 'black' }}><Typography variant="h5">contact</Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Footer></Footer>
        </ThemeProvider>
    );
}
