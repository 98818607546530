import * as React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import pic10 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-24.jpg";
import pic11 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-25.jpg";
import pic12 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-26.jpg";
import pic13 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-27.jpg";
import pic14 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-28.jpg";
import pic16 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-30.jpg";
import pic17 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-31.jpg";
import pic18 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-79.jpg";
import pic19 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-80.jpg";
import pic20 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-81.jpg";
import pic21 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-82.jpg";
import pic22 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-83.jpg";
import pic23 from "./photography/fashion/post-apocalyptic high fashion/leonidpesenti-84.jpg";
import Footer from "../Footer";

export default function PostApocalypticHighFashion() {
    const theme = createTheme({
        typography: {
            h1: {
                fontFamily: "Helvetica",
                fontSize: "3rem"
            },
            h2: {
                fontFamily: "Helvetica",
                fontSize: "2rem"
            },
        }
    });

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
    };

    const themeForBreakpoints = createTheme();

    const sm = useMediaQuery(themeForBreakpoints.breakpoints.down('sm'));
    const lg = useMediaQuery(themeForBreakpoints.breakpoints.up('lg'));
    const tablet = useMediaQuery('(min-width:768px) and (max-width:1000px)');
    
    let images = [pic14, pic16, pic10, pic23, pic13, pic19, pic17, pic18];

    const [open, setOpen] = React.useState(false);
    const [modalData, setModalData] = React.useState(null);
    const [imageHeight, setImageHeight] = React.useState(null);
    const [imageWidth, setImageWidth] = React.useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Manual spacing adjustments (consistent with your example)
    const containerWidth = "80vw"; // Base container width
    const titleMargin = "2vw"; // Margin for the title
    const titleSpacing = "4vw"; // Spacing between title and text
    const textSpacing = "4vw"; // Spacing between text and images

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="0 auto"  // Centering the Box
                padding="0"  // Removing internal padding
                width={containerWidth}  // Box width 80vw
                boxSizing="border-box"  // Prevent overflow due to padding/borders
                flexDirection="column"
            >
                {/* Title with spacing */}
                <Typography 
                    style={{ 
                        color: 'black', 
                        textDecoration: 'none', 
                        fontSize: "2rem", 
                        margin: `${titleMargin} 0` // Added vertical margins for the title
                    }}
                >
                    // post-apocalyptic high fashion
                </Typography>
                
                {/* Caption with spacing between text and photos */}
                <Typography 
                    style={{ 
                        fontSize: "1rem", 
                        marginBottom: textSpacing // Applying adjusted spacing to keep it tighter
                    }}
                >
                    <a target="_blank" href="https://drive.google.com/file/d/1ML88kbNqo0kuawmwDJGm3s8TYRYQKRiO/view?usp=sharing">english</a> and 
                    <a target="_blank" href="https://drive.google.com/file/d/1H5WCzKJXH2qsdO6XXL4Yc2eml34-Yjol/view?usp=sharing"> italian</a> versions of the project that was exhibited at 
                    <a target="_blank" href="https://www.gallerialaloggia.ch"> Galleria La Loggia</a><br /><br />
                    Calcareous slopes which display a desolated landscape, that offer nothing but dead trees and traces of an ancient village, 
                    encased by tall mountains in the process of losing their snowy tops to the approach of warmer seasons. What are the values of beauty and fashion in a dead world?<br /><br />
                    <a target="_blank" href="https://www.instagram.com/p/CiTKJFiq_UR/">some pictures from the gallery</a><br /><br />
                    <span style={{ marginLeft: "2rem" }}>// styling:</span> <a target="_blank" href="https://www.instagram.com/cielo_solari/">Cielo Solari</a><br />
                    <span style={{ marginLeft: "2rem" }}>// models:</span> <a target="_blank" href="https://www.instagram.com/nassiali_/">Nassi</a> / 
                    <a target="_blank" href="https://www.instagram.com/zoecaminada/">Zoe</a>
                </Typography>

                {/* Grid for Images with more space */}
                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
                    {images.map((image) => {
                        if (tablet) {
                            return (
                                <Grid item xs={6} key={image} style={{ width: "80vw", display: "flex", justifyContent: "center" }}>
                                    <img src={image} style={{ width: "100%" }} />
                                </Grid>
                            );
                        }
                        else if (sm) {
                            return (
                                <Grid item xs={12} key={image} style={{ width: "80vw", display: "flex", justifyContent: "center" }}>
                                    <img src={image} style={{ width: "100%" }} />
                                </Grid>
                            );
                        }
                        else {
                            return (
                                <Grid item xs={3} key={image} style={{ display: "flex", justifyContent: "center" }}>
                                    <Button onClick={() => {
                                        setModalData(image);
                                        handleOpen();
                                        const img = new Image();
                                        img.src = image;
                                        img.onload = () => {
                                            setImageHeight(img.height);
                                            setImageWidth(img.width);
                                        };
                                    }}>
                                        <img src={image} style={{ width: "100%" }} />
                                    </Button>
                                </Grid>
                            );
                        }
                    })}
                </Grid>
            </Box>

            {/* Modal for Image Display */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <img src={modalData} height={imageHeight > 900 ? imageHeight * 0.50 : "100%"} width={imageHeight > 900 ? imageWidth * 0.50 : "100%"} />
                </Box>
            </Modal>

            {/* Footer */}
            <Footer></Footer>
        </ThemeProvider>
    );
}
